import ApiCall from "utils/ApiCall";
import useSWR from "swr";

const useDwssPermisionByGroupId = (groupId) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/dwss-permissions-by-group?groupId=${groupId}`,
    ApiCall.swrFetcher(),
  );

  return {
    dwssGroupPermissionsData: data,
    dwssGroupPermissionsLoading: !error && !data,
    dwssGroupPermissionsError: error,
  };
};

const updateDwssGroup = async (data) => {
  try {
    const { groupId, newDwssUserOrGroupPermissions: newDwssGroupPermissions } = data;
    return await ApiCall.post(`${process.env.REACT_APP_API}/api/dwss-group/${groupId}`, newDwssGroupPermissions);
  } catch (err) {
    throw err;
  }
};


export { useDwssPermisionByGroupId, updateDwssGroup };
