import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Policy from '@material-ui/icons/Policy';
// import AssignmentIcon from "@material-ui/icons/Assignment";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import DescriptionIcon from "@material-ui/icons/Description";
import { Link as RouterLink } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

function ListItemLink(props) {
  const { handleClick, icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem onClick={handleClick} button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export default function MainListItems({ setTitle }) {
  return (
    <div>
      {/* <ListItemLink
        handleClick={() => {
          setTitle("Home");
        }}
        to="/"
        primary="Home"
        icon={
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
        }
      /> */}
      <ListItem button disabled>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItemLink
        handleClick={() => {
          setTitle("Ethereum Monitor");
        }}
        to="/ethereum-monitor"
        primary="DSD Blockchain"
        icon={
          <ListItemIcon>
            <ViewColumnIcon />
          </ListItemIcon>
        }
      />

      <ListItemLink
        handleClick={() => {
          setTitle("Dashboard");
        }}
        to="/dashboard"
        primary="Dashboard"
        icon={
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
        }
      />

      <ListItemLink
        handleClick={() => {
          setTitle("Contracts");
        }}
        to="/contract"
        primary="Contracts"
        icon={
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
        }
      />

      <ListItemLink
        handleClick={() => {
          setTitle("Users");
        }}
        to="/user"
        primary="Members"
        icon={
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
        }
      />

      <ListItemLink
        handleClick={() => {
          setTitle("Group");
        }}
        to="/group"
        primary="Group"
        icon={
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
        }
      />

      {/*
      <ListItemLink
        handleClick={() => {
          setTitle("Permission");
        }}
        to="/permission"
        primary="Permission"
        icon={
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
        }
      />
      */}

      <ListItemLink
        handleClick={() => {
          setTitle("Login History");
        }}
        to="/login-logs"
        primary="Login Logs"
        icon={
          <ListItemIcon>
            <FindInPageIcon />
          </ListItemIcon>
        }
      />
      <ListItemLink
        handleClick={() => {
          setTitle("Activity Logs");
        }}
        to="/activity-logs"
        primary="Activity Logs"
        icon={
          <ListItemIcon>
            <Policy />
          </ListItemIcon>
        }
      />

      <ListItemLink
        handleClick={() => {
          delete localStorage["accessToken"];
        }}
        to="/login"
        primary="Logout"
        icon={
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
        }
      />
    </div>
  );
}
