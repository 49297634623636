import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Chartbox } from "components/Dashboard";
// import * as R from "ramda";
import {
  getAllContract,
  // getContractById,
  // getContractByIdRics,
  // getContractByIdSiteDiary,
  // getContractByIdPhoto,
} from "services/Contract";
import { getUsers } from "services/User";
import { getStatuses } from "services/Status";
import { searchBoxStyles } from "styles";

export default function Dashboard() {
  const useStyles = makeStyles((theme) => searchBoxStyles(theme));
  const classes = useStyles();

  const [members, setMembers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loginedUserInfo = JSON.parse(
          atob(localStorage.accessToken.split(".")[1]),
        );

        const contracts = await getAllContract();
        const members = await getUsers();
        const statuses = await getStatuses();

        setStatuses(statuses);
        setContracts(contracts);
        setMembers(members);
      } catch (err) {
        console.log("user.js", err);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ margin: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5rem",
          alignItems: "center",
        }}
      >
        <div>
          <h4>Dashboard</h4>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        ></div>
      </div>

      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={12} lg={12}>
          <Chartbox
            title="Total Users"
            totalNumber={members.length}
            data={[
              <>
                <Typography color="textSecondary">
                  Active:
                  {
                    members.filter(({ status }) => status.code === "active")
                      .length
                  }
                </Typography>
                <Typography color="textSecondary">
                  Suspended:
                  {
                    members.filter(({ status }) => status.code === "suspended")
                      .length
                  }
                </Typography>
              </>,
            ]}
          />
        </Grid>

        {contracts.map((contract, i) => (
          <Grid item xs={12} md={3} lg={3}>
            <Chartbox
              key={i}
              title={contract.contractNo}
              totalNumber={contract.projectLocation} data={[
                <>
                  <Typography color="textSecondary">
                    Contractor: {contract.projectContractor}
                  </Typography>
                  <Typography color="textSecondary">
                    Risc: {contract.noOfRisc}
                  </Typography>
                  <Typography color="textSecondary">
                    Site Diary: {contract.noOfSiteDiary}
                  </Typography>
                  <Typography color="textSecondary">
                    Photo: {contract.noOfPhoto}
                  </Typography>
                </>,
              ]}
              activeNumber="154"
              suspendedNumber="14"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
