const tableStyles = (theme) => {
  return {
    table: {
      minWidth: 650,
    },
    header: { 
      fontWeight: "700",
    },
  };
};

export default tableStyles;
