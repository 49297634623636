import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { LogViewTable } from "components/ActivityLog";
import Pagination from '@material-ui/lab/Pagination';
import { useActivityLogs } from "services/Logs";
import { searchBoxStyles } from "styles";

function PaginationOutlined(props) {
  const {pagination, setPagination} = props
  return (
    <div style={{margin:".5rem 0", display:"flex"}}>
      <Pagination 
        style={{marginLeft:"auto"}}
        count={Math.ceil(+pagination.count / +pagination.items)} 
        variant="outlined" 
        color="primary" 
        page={pagination.page}
        onChange={(_,page)=>{
          setPagination(prev=>({...prev, page}))
        }}
        />
    </div>
  );
}

export default function ActivityLog() {
  const useStyles = makeStyles((theme) => searchBoxStyles(theme));
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    page:1,
    totalPages: null,
    items:10,
    count:null,
  })
  // const [permission, setPermission] = useState([]);
  const { activityLogsData, activityLogsLoading, activityLogsError } = useActivityLogs({pagination,setPagination});

  const activityLogs = activityLogsData?.data;

  const searchLogs = () => {
    const tuneString = (s) => (!s ? "" : s.toString().toLowerCase());

    const filteredLogs = activityLogs?.filter((log) => {
      // console.log("?", Object.keys(group));
      return !!Object.keys(log).find((key) =>
        tuneString(log[key]).includes(tuneString(searchText)),
      );
    });

    return filteredLogs;
  };

  return (
    <div style={{ margin: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5rem",
          alignItems: "center",
        }}
      >
        <div>
          <h4>Logging</h4>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Paper component="form" className={classes.root}>
            <SearchIcon />
            <InputBase
              name={searchText}
              className={classes.input}
              placeholder="Search Log"
              inputProps={{ "aria-label": "search group" }}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </Paper>
        </div>
      </div>

      {//loginLogsLoading || loading ? (
        activityLogsLoading ? (
          <>loading...</>
        ) : (
          <>
            <LogViewTable logsData={searchLogs()} />
            <PaginationOutlined {...{pagination:{...pagination, count:activityLogsData?.count},setPagination}}  />
          </>
        )}
    </div>
  );
}
