import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import modalSlice from "./slice/modalSlice"

const reducer = combineReducers({
  user: userSlice,
  modal: modalSlice,
});

const store = configureStore({
  reducer,
  middleware: [...getDefaultMiddleware({ thunk: false })]
});

export default store;
