import React, { useState, useEffect } from "react";
import { mutate } from "swr";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { UserListTable, UserDialog } from "components/User";
// import * as R from "ramda";
import {
  useGetUsersByMyGroup,
  createUser,
  updateUser,
  // deleteUser,
} from "services/User";
import { useGroups, getGroupById } from "services/Group";

import { getStatuses } from "services/Status";
import { validateUser } from "utils/validationRules";
import { searchBoxStyles } from "styles";

const defaultMember = {
  email: "",
  lastName: "",
  firstName: "",
  roles: "",
  permissionId: null,
  remark: "",
};

export default function User() {
  const useStyles = makeStyles((theme) => searchBoxStyles(theme));
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [member, setMember] = useState(defaultMember);
  const [statuses, setStatuses] = useState([]);
  const [searchText, setSearchText] = useState("");
  // const [permission, setPermission] = useState([]);
  const { usersData, usersLoading, usersError } = useGetUsersByMyGroup();
  const { groupsData, groupsLoading, groupsError } = useGroups();

  const groups = groupsData?.data;
  const members = usersData?.data?.users;
  const myGroup = usersData?.data?.group;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const loginedUserInfo = JSON.parse(
        //   atob(localStorage.accessToken.split(".")[1]),
        // );

        const statuses = await getStatuses();
        //console.log("got users group", members, group);

        setStatuses(statuses);
        setLoading(false);
      } catch (err) {
        console.log("user.js", err);
      }
    };

    fetchData();
  }, []);

  const formatUser = (member) => {
    const { organization, role, email } = member;

    const type = role === "Contract Admin" ? "org_admin" : "org_member";
    const orgRole =
      role === "Contract Admin"
        ? `${organization}Admin`
        : `${organization}Member`;

    const groupId = groups.find((item) => item.name === organization)?.id;

    return {
      ...member,
      username: email,
      role: orgRole,
      organization,
      type,
      groupId,
    };
  };

  const insertUser = async (member) => {
    member.statusId = statuses.find(({code})=>code==="pending")?.id;
    const { value: data, error } = validateUser.validate(member, {
      allowUnknown: true,
      abortEarly: false,
      stripUnknown: true,
    });
    //console.log("value", data);
    //console.log("error", !error, error?.details);
    if (error) {
      setErrorMessages(error?.details);
      return;
    }

    setErrorMessages([]);
    const newMember = await createUser(data);

    mutate(`${process.env.REACT_APP_API}/api/users/by-group`);
    setMember(defaultMember);
    setOpen(false);
  };

  const editUser = async (member) => {
    try {
      delete member["createdAt"];
      delete member["updatedAt"];
      delete member["status"];

      if (!member.isPasswordChanged) {
        delete member["password"];
      }

      //console.log("omem", member);
      const { value: data, error } = validateUser.validate(member, {
        allowUnknown: true,
        abortEarly: false,
        stripUnknown: true,
      });
      //console.log("value", data);
      //console.log("error", !error, error?.details);
      if (error) {
        setErrorMessages(error?.details);
        return;
      }

      const updatedMember = await updateUser(data);
      setErrorMessages([]);

      mutate(`${process.env.REACT_APP_API}/api/users/by-group`);
      setMember(defaultMember);
      setOpen(false);
    } catch (err) {
      console.error("update user error", err);
    }
  };

  const insertOrUpdateUser = async (member) => {
    if (!member) return;

    member = formatUser(member);
    // console.log("member", member);

    if (!!member.id) {
      return editUser(member);
    }

    return insertUser(member);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setMember(defaultMember);
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value, type, checked, art } = event.target;
    console.log("ds", name, value, art, type, checked);
    if (name === "password") {
      member.isPasswordChanged = true;
    }

    setMember({ ...member, [name]: value });
  };

  const suspendUser = (member) => {
    member = { ...member, statusId: 2 };
    editUser(member);
  };

  const activateUser = (member) => {
    member = { ...member, statusId: 1 };
    editUser(member);
  };

  const searchMembers = () => {
    const tuneString = (s) => (!s ? "" : s.toString().toLowerCase());

    const filteredMembers = members?.filter((member) => {
      // console.log("?", Object.keys(member));
      return !!Object.keys(member).find((key) =>
        tuneString(member[key]).includes(tuneString(searchText)),
      );
    });

    return filteredMembers;
  };

  return (
    <div style={{ margin: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5rem",
          alignItems: "center",
        }}
      >
        <div>
          <h4>Members</h4>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Paper component="form" className={classes.root}>
            <SearchIcon />
            <InputBase
              name={searchText}
              className={classes.input}
              placeholder="Search Member"
              inputProps={{ "aria-label": "search member" }}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </Paper>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            + Member
          </Button>
        </div>
      </div>

      {groupsLoading || usersLoading || loading ? (
        <>loading...</>
      ) : (
        <UserListTable
          members={searchMembers()}
          setMember={setMember}
          setOpen={setOpen}
          activateUser={activateUser}
          suspendUser={suspendUser}
          statuses={statuses}
          groups={groups}
        />
      )}

      <UserDialog
        open={open}
        handleChange={handleChange}
        handleClose={handleClose}
        member={member}
        myGroup={myGroup}
        groups={groups}
        insertOrUpdateUser={insertOrUpdateUser}
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
      />
    </div>
  );
}
