import ApiCall from "utils/ApiCall";
import useSWR from "swr";

const useGroups = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/grp`,
    ApiCall.swrFetcher(),
  );
  //console.log("useGroups", data);

  return {
    groupsData: data,
    groupsLoading: !error && !data,
    groupsError: error,
  };
};

const getGroupById = async (id) => {
  return await ApiCall.get(`${process.env.REACT_APP_API}/api/grp/${id}`);
};

const createGroup = async (data) => {
  return await ApiCall.post(`${process.env.REACT_APP_API}/api/group`, data);
};

const updateGroup = async (data) => {
  return await ApiCall.update(
    `${process.env.REACT_APP_API}/api/group/${data.id}`,
    data,
  );
};

const deleteGroup = async (id) => {
  return await ApiCall.delete(`${process.env.REACT_APP_API}/api/group/${id}`);
};

export { useGroups, getGroupById, createGroup, updateGroup, deleteGroup };
