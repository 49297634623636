import ApiCall from "utils/ApiCall";
import useSWR from "swr";

// const route = `${process.env.REACT_APP_API}/api/users`;

const getUsers = async () => {
  return await ApiCall.get(`${process.env.REACT_APP_API}/api/users`);
};

const useUsers = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/users`,
    ApiCall.swrFetcher(),
  );
  //console.log("datass", data);

  return {
    allUserData: data,
    allUserLoading: !error && !data,
    allUserError: error,
  };
};

const useGetUsersByMyGroup = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/users/by-group`,
    ApiCall.swrFetcher(),
  );
  //console.log("datass", data);

  return {
    usersData: data,
    usersLoading: !error && !data,
    usersError: error,
  };
};

const createUser = async (data) => {
  return await ApiCall.post(
    `${process.env.REACT_APP_API}/api/user/register`,
    data,
  );
};

const updateUser = async (data) => {
  return await ApiCall.update(
    `${process.env.REACT_APP_API}/api/user/${data.id}`,
    data,
  );
};

const deleteUser = async (id) => {
  return await ApiCall.delete(`${process.env.REACT_APP_API}/api/user/${id}`);
};

export { getUsers, useUsers, useGetUsersByMyGroup, createUser, updateUser, deleteUser };
