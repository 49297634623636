import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "@material-ui/core/Select";
import { getPermissions } from "services/Permission";
import styled from "styled-components";
import useSWR, { mutate } from "swr";

const ErrorMessageBox = styled.div`
  color: red;
  margin: 0 1.2rem;
`;

function GroupDialog({
  open,
  handleChange,
  handleClose,
  group,
  statuses,
  insertOrUpdateGroup,
  errorMessages,
  setErrorMessages,
}) {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setPermissions(await getPermissions());
    };
    getData();
  }, []);

  const getPermissionByCode = (code) => {
    return permissions.find((item) => item.code === code);
  };

  const statusOptions = () => {
    return statuses
      ?.filter(({ code }) => code !== 'pending')
      ?.map(({ name, id }, i) => <MenuItem key={i} value={id}>{name}</MenuItem>)
      ?.reverse();
  };

  const invalidInput = (key) =>
    !!errorMessages.find((item) => item?.context?.key === key);

  //console.log("error msg", errorMessages);
  if (permissions.length === 0) return <>loading</>;
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="addGroup">
      <DialogTitle id="addGroup">+ Group</DialogTitle>
      {errorMessages.map(({ message }, i) => (
        <ErrorMessageBox key={i}>{message}</ErrorMessageBox>
      ))}
      <DialogContent>
        <TextField
          required
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="name"
          fullWidth
          value={group.name}
          onChange={(e) => {
            handleChange(e);
          }}
          error={invalidInput("name")}
        />
        <TextField
          //required
          margin="dense"
          name="remark"
          id="remark"
          label="Remarks"
          type="text"
          value={group.remark}
          onChange={handleChange}
          fullWidth
          error={invalidInput("remark")}
        />
        <InputLabel id="status" style={{ marginTop: "1rem" }}>
          Status
        </InputLabel>
        <Select
          labelId="statusId"
          id="statusId"
          name="statusId"
          type="text"
          value={group.statusId}
          onChange={handleChange}
          style={{ width: "100%" }}
        >
          {statusOptions()}
        </Select>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              await insertOrUpdateGroup(group);
              mutate(`${process.env.REACT_APP_API}/api/users/by-group`);
              setLoading(false);

            } catch (err) {
              //console.log(errorMessages)
              setErrorMessages([err])
              setLoading(false)
            }
          }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GroupDialog;
