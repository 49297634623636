import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ManageUsersBtn from "components/Group/ManageUsersBtn"
import PermissionBtn from "components/Permission/PermissionBtn";
import { tableStyles } from "styles";
import { useGetUsersByMyGroup } from "services/User";
import { updateDwssGroup } from "services/DwssGroup";
import useSWR, { mutate } from "swr";
import Delete from "@material-ui/icons/Delete";
import { deleteGroup } from "services/Group";

function GroupListTable({
  members,
  setMember,
  setOpen,
  setGroup,
  statuses,
  groups,
}) {
  const useStyles = makeStyles((theme) => tableStyles(theme));
  const classes = useStyles();

  const { usersData, usersLoading, usersError } = useGetUsersByMyGroup();

  // const getStatusName = (statusId) => {
  //   return statuses.find(({ id }) => id === statusId)["name"];
  // };

  // const getGroupName = (groupId) => {
  //   return groups.find(({ id }) => id === groupId)["name"];
  // };

  const saveGroupPermissions = async (data) => {
    const { groupId, newDwssUserOrGroupPermissions } = data;
    if (!groupId || !newDwssUserOrGroupPermissions) return
    // console.log('updating...', data)
    const response = await updateDwssGroup(data);
    //console.log(response)

    mutate(`${process.env.REACT_APP_API}/api/dwss-permissions-by-group?groupId=${groupId}`);
  }

  return (!usersLoading) && (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="left">
              #
            </TableCell>
            <TableCell className={classes.header} align="right">
              Name
            </TableCell>
            <TableCell className={classes.header} align="right">
              Remarks
            </TableCell>
            <TableCell className={classes.header} align="right">
              Status
            </TableCell>
            <TableCell className={classes.header} align="center">
              Users
            </TableCell>
            <TableCell className={classes.header} align="center">
              Permission
            </TableCell>
            <TableCell className={classes.header} align="right" width={{ width: '100px' }}>
              Edit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!groups &&
            groups.map((group, i) => {
              return (
                <TableRow key={group.id}>
                  <TableCell align="left">{i + 1}</TableCell>
                  <TableCell align="right">{group.name}</TableCell>
                  <TableCell align="right">{group.remark}</TableCell>
                  <TableCell align="right">
                    {group.status.name}
                  </TableCell>
                  <TableCell align="center">
                    {
                      usersData?.data?.group.name.toLowerCase() === 'master' ?
                        <ManageUsersBtn group={group} /> :
                        group.name === usersData?.data?.group.name ?
                          <ManageUsersBtn group={group} /> :
                          <></>
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      usersData?.data?.group.name.toLowerCase() === 'master' ?
                        <PermissionBtn
                          displayName={group.name}
                          groupId={group.id}
                          savePermissions={saveGroupPermissions}
                        /> :
                        group.name === usersData?.data?.group.name ?
                          <PermissionBtn
                            displayName={group.name}
                            groupId={group.id}
                            savePermissions={saveGroupPermissions}
                          /> :
                          <></>
                    }
                  </TableCell>

                  <TableCell align="right">
                    {/* show delete button */}
                    <Delete
                      fontSize="small"
                      onClick={async () => {
                        if (!window.confirm(`You are about to remove group ${group.name||''}`))
                          return
                        await deleteGroup(group.id)
                        mutate(`${process.env.REACT_APP_API}/api/grp`)
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    {
                      /* show edit button */
                      usersData?.data?.group.name.toLowerCase() === 'master' ?
                        <EditIcon
                          fontSize="small"
                          onClick={() => {
                            setGroup({ ...group, index: i });
                            setOpen(true);
                          }}
                          style={{ cursor: "pointer" }}
                        /> :
                        group.name === usersData?.data?.group.name ?
                          <EditIcon
                            fontSize="small"
                            onClick={() => {
                              setGroup({ ...group, index: i });
                              setOpen(true);
                            }}
                            style={{ cursor: "pointer" }}
                          /> :
                          <></>
                    }
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GroupListTable;
