import { makeStyles } from "@material-ui/core/styles";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ContractGroupDialog from "./ContractGroupDialog";
import { tableStyles } from "styles";

function ContractTable({
  contracts,
  contract,
  groups,
  handleSaveContractGroup,
  handleSaveContract,
  newContract,
  setNewContract,
  selectedGroupIds,
  setSelectedGroupIds,
  setContract,
  setContracts,
  statuses,
}) {
  const useStyles = makeStyles((theme) => tableStyles(theme));
  const classes = useStyles();

  const getStatusName = (statusId) => {
    return statuses.find(({ id }) => id === statusId)["name"];
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>#</TableCell>
            <TableCell className={classes.header}>Project ID</TableCell>
            <TableCell className={classes.header}>Contract Number</TableCell>
            <TableCell className={classes.header}>Project Name</TableCell>
            <TableCell className={classes.header}>Project Location</TableCell>
            <TableCell className={classes.header}>Project Client</TableCell>
            <TableCell className={classes.header}>Project Consultant</TableCell>
            <TableCell className={classes.header}>Project Contractor</TableCell>
            <TableCell className={classes.header}>Status</TableCell>
            <TableCell className={classes.header}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!contracts &&
            contracts.map((item, i) => {
              const {
                id,
                projectId,
                contractNo,
                projectName,
                projectLocation,
                projectClient,
                projectConsultant,
                projectContractor,
                statusId,
              } = item;

              return (
                <TableRow key={id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{projectId}</TableCell>
                  <TableCell>{contractNo}</TableCell>
                  <TableCell>{projectName}</TableCell>
                  <TableCell>{projectLocation}</TableCell>
                  <TableCell>{projectClient}</TableCell>
                  <TableCell>{projectConsultant}</TableCell>
                  <TableCell>{projectContractor}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      {statusId && getStatusName(statusId)}
                      <>
                        {statusId === 1 && (
                          <BlockIcon
                            fontSize="small"
                            onClick={() =>
                              handleSaveContract({ ...item, statusId: 2 })
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {statusId === 2 && (
                          <CheckCircleOutlineIcon
                            fontSize="small"
                            onClick={() =>
                              handleSaveContract({ ...item, statusId: 1 })
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </>
                    </div>
                  </TableCell>
                  <TableCell>
                    <ContractGroupDialog
                      key={i}
                      index={i}
                      groups={groups}
                      handleSaveContractGroup={handleSaveContractGroup}
                      handleSaveContract={handleSaveContract}
                      selectedGroupIds={selectedGroupIds}
                      setSelectedGroupIds={setSelectedGroupIds}
                      contract={contract}
                      contracts={contracts}
                      newContract={newContract}
                      setNewContract={setNewContract}
                      setContract={setContract}
                      setContracts={setContracts}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ContractTable;
