import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "@material-ui/core/Select";
import { getPermissions } from "services/Permission";
import styled from "styled-components";

const ErrorMessageBox = styled.div`
  color: red;
  margin: 0 1.2rem;
`;

function UserDialog({
  open,
  handleChange,
  handleClose,
  member,
  myGroup,
  groups,
  insertOrUpdateUser,
  errorMessages,
  setErrorMessages,
}) {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setPermissions(await getPermissions());
    };
    getData();
  }, []);

  const getPermissionByCode = (code) => {
    return permissions.find((item) => item.code === code);
  };

  const groupOptions = () => {
    return myGroup?.name === "Master"
      ? groups
        ?.map(({ name },i) => <MenuItem key={i} value={name}>{name}</MenuItem>)
        ?.reverse()
      : [<MenuItem value={myGroup?.name}>{myGroup?.name}</MenuItem>];
  };

  const invalidInput = (key) =>
    !!errorMessages.find((item) => item?.context?.key === key);

  //console.log("error msg", errorMessages);
  if (permissions.length === 0) return <>loading</>;
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="addMember">
      <DialogTitle id="addMember">+ Member</DialogTitle>
      {errorMessages.map(({ message },i) => (
        <ErrorMessageBox key={i}>{message}</ErrorMessageBox>
      ))}
      <DialogContent>
        <TextField
          required
          autoFocus
          margin="dense"
          id="email"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          value={member.email}
          onChange={(e) => {
            handleChange(e);
          }}
          error={invalidInput("email")}
        />
        <TextField
          required
          autoFocus
          margin="dense"
          name="lastName"
          id="lastName"
          label="Last Name"
          type="text"
          value={member.lastName}
          onChange={handleChange}
          fullWidth
          error={invalidInput("lastName")}
        />
        <TextField
          required
          autoFocus
          margin="dense"
          name="firstName"
          id="firstName"
          label="First Name"
          type="text"
          value={member.firstName}
          onChange={handleChange}
          fullWidth
          error={invalidInput("firstName")}
        />
        <TextField
          required
          autoFocus
          margin="dense"
          name="password"
          id="password"
          label="Password"
          type="password"
          onChange={handleChange}
          fullWidth
          error={invalidInput("password")}
        />
        <InputLabel id="organization" style={{ marginTop: "1rem" }}>
          Organisation
        </InputLabel>
        <Select
          labelId="organization"
          id="organization"
          name="organization"
          type="text"
          value={member.organization}
          onChange={handleChange}
          style={{ width: "100%" }}
        >
          {groupOptions()}
        </Select>

        <InputLabel id="role" style={{ marginTop: "1rem" }}>
          Role
        </InputLabel>
        <Select
          labelId="role"
          id="role"
          name="role"
          type="text"
          value={
            !!member.role && member.role.includes("Admin")
              ? "Contract Admin"
              : !!member.role && member.role.includes("Member")
                ? "Contract Member"
                : ""
          }
          onChange={handleChange}
          style={{ width: "100%" }}
        >
          <MenuItem value="Contract Admin">Contract Admin</MenuItem>
          <MenuItem value="Contract Member">Contract Member</MenuItem>
        </Select>

        <FormControl component="fieldset" style={{ marginTop: "1rem" }}>
          <FormLabel component="legend">Permissions</FormLabel>
          <RadioGroup
            onChange={handleChange}
            aria-label="permissions"
            name="permissionId"
            value={Number(member.permissionId)}
            art="number"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value={getPermissionByCode("public").id}
              control={<Radio />}
              label={getPermissionByCode("public").name}
            />
            <FormControlLabel
              value={getPermissionByCode("read_write").id}
              control={<Radio />}
              label={getPermissionByCode("read_write").name}
            />
            <FormControlLabel
              value={getPermissionByCode("read").id}
              control={<Radio />}
              label={getPermissionByCode("read").name}
            />
          </RadioGroup>
        </FormControl>

        <InputLabel id="remark" type="text" style={{ marginTop: "1rem" }}>
          Remarks
        </InputLabel>
        <TextareaAutosize
          aria-label="remark"
          minRows={3}
          id="remark"
          label="Remarks"
          type="text"
          name="remark"
          value={member.remark}
          onChange={handleChange}
          style={{ width: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              await insertOrUpdateUser(member);
              setLoading(false);

            } catch (err) {
              //console.log(errorMessages)
              setErrorMessages([err])
              setLoading(false)
            }
          }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserDialog;
