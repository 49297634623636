import React, { useState, useEffect } from "react";
import useSWR, { mutate } from "swr";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { GroupListTable, GroupDialog } from "components/Group";
// import * as R from "ramda";
import { useGroups, getGroupById, createGroup } from "services/Group";

import { getStatuses } from "services/Status";
import { validateGroup } from "utils/validationRules";
import { searchBoxStyles } from "styles";

const defaultMember = {
  email: "",
  lastName: "",
  firstName: "",
  roles: "",
  permissionId: null,
  remark: "",
};

const defaultGroup = {
  name: "",
  remarks: null,
  statusId: null,
}

export default function Group() {
  const useStyles = makeStyles((theme) => searchBoxStyles(theme));
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [member, setMember] = useState(defaultMember);
  const [group, setGroup] = useState(defaultGroup);
  const [statuses, setStatuses] = useState([]);
  const [searchText, setSearchText] = useState("");
  // const [permission, setPermission] = useState([]);
  const { groupsData, groupsLoading, groupsError } = useGroups();

  const groups = groupsData?.data;
  //const members = usersData?.users;
  //const myGroup = usersData?.group;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const loginedUserInfo = JSON.parse(
        //   atob(localStorage.accessToken.split(".")[1]),
        // );

        const statuses = await getStatuses();
        //console.log("got users group", members, group);

        console.log('ggggg', groupsData?.data)
        setStatuses(statuses);
        //setGroups(groupsData?.data);
        setLoading(false);
      } catch (err) {
        console.log("group.js", err);
      }
    };

    fetchData();
  }, []);

  const insertOrUpdateGroup = async (group) => {
    if (!group) return;

    //group.statusId = statuses.find(({code})=>code==="active")?.id;
    const { value: data, error } = validateGroup.validate(group, {
      allowUnknown: true,
      abortEarly: false,
      stripUnknown: true,
    });
    console.log("value", data);
    console.log("error", !error, error?.details);
    if (error) {
      setErrorMessages(error?.details);
      return;
    }

    setErrorMessages([]);
    const result = await createGroup(data);

    mutate(`${process.env.REACT_APP_API}/api/grp`);
    //setMember(defaultGroup);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setGroup(defaultGroup);
    setOpen(true);
  };

  const handleClose = () => {
    setMember(defaultMember);
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value, type, checked, art } = event.target;
    console.log("ds", name, value, art, type, checked);

    setGroup({ ...group, [name]: value });
  };

  const searchGroups = () => {
    const tuneString = (s) => (!s ? "" : s.toString().toLowerCase());

    const filteredMembers = groups?.filter((group) => {
      // console.log("?", Object.keys(group));
      return !!Object.keys(group).find((key) =>
        tuneString(group[key]).includes(tuneString(searchText)),
      );
    });

    return filteredMembers;
  };

  return (
    <div style={{ margin: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5rem",
          alignItems: "center",
        }}
      >
        <div>
          <h4>Groups</h4>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Paper component="form" className={classes.root}>
            <SearchIcon />
            <InputBase
              name={searchText}
              className={classes.input}
              placeholder="Search Group"
              inputProps={{ "aria-label": "search group" }}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </Paper>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            + Group
          </Button>
        </div>
      </div>

      {groupsLoading || loading ? (
        <>loading...</>
      ) : (
        <GroupListTable
          groups={searchGroups()}
          setGroup={setGroup}
          setOpen={setOpen}
          statuses={statuses}
        />
      )}

      <GroupDialog
        open={open}
        handleChange={handleChange}
        handleClose={handleClose}
        group={group}
        statuses={statuses}
        insertOrUpdateGroup={insertOrUpdateGroup}
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
      />
    </div>
  );
}
