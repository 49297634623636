import ApiCall from "utils/ApiCall";

// const route = `${process.env.REACT_APP_API}/api/users`;

const getContractGroups = async () => {
  return await ApiCall.get(`${process.env.REACT_APP_API}/api/contract-group`);
};

const getContractGroupByContractId = async (id) => {
  return await ApiCall.get(
    `${process.env.REACT_APP_API}/api/contract-group/${id}`,
  );
};

const saveContractGroup = async (data) => {
  return await ApiCall.post(
    `${process.env.REACT_APP_API}/api/contract-group`,
    data,
  );
};

const updateContractGroup = async (data) => {
  return await ApiCall.update(
    `${process.env.REACT_APP_API}/api/contract-group/${data.id}`,
    data,
  );
};

const deleteContractGroup = async (id) => {
  return await ApiCall.delete(
    `${process.env.REACT_APP_API}/api/contract-group/${id}`,
  );
};

export {
  getContractGroups,
  getContractGroupByContractId,
  saveContractGroup,
  updateContractGroup,
  deleteContractGroup,
};
