import ApiCall from "utils/ApiCall";

// const route = `${process.env.REACT_APP_API}/api/users`;

const getStatuses = async () => {
  return await ApiCall.get(`${process.env.REACT_APP_API}/api/statuses`);
};

const createStatus = async (data) => {
  return await ApiCall.post(`${process.env.REACT_APP_API}/api/status`, data);
};

export { getStatuses, createStatus };
