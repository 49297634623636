import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
  },
  reducers: {
    setUserOpen: (state, action) => {
      const { userType } = action.payload;
      return { ...state, type: userType };
    },
  }
});

export const { setUserOpen, setValuationDateConfirm, setUserClose } = UserSlice.actions;

export default UserSlice.reducer;
