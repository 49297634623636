import { makeStyles } from "@material-ui/core/styles";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { tableStyles } from "styles";
import ShowContractsBtn from "components/User/ShowContractsBtn"


// checkbox for view & edit permission
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { usePermissions } from "services/Permission";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

const PermissionMatrixTable = ({
  dwssUserOrGroupPermissions,
  newDwssUserOrGroupPermissions,
  isChecked,
  handlePermissionCheckbox,
}) => {
  const { permissionsData: permissions, permissionsLoading, permissionsError } = usePermissions();
  const classes = useStyles();

  if (!dwssUserOrGroupPermissions)
    return <>loading...</>
  // console.log('dwssUserOrGroupPermissions', dwssUserOrGroupPermissions)
  // console.log('newDwssUserOrGroupPermissions', newDwssUserOrGroupPermissions)

  const { groupByContract, permissionCategories } = dwssUserOrGroupPermissions;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '2rem' }}>{/* this is top left corner empty cell */}</TableCell>
            {permissionCategories.map(type =>
              <TableCell align='center' style={{ width: '5rem' }}>
                {type.name}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{/* this is top left corner empty cell */}</TableCell>
            {permissionCategories.map(type =>
              <TableCell align='center' style={{ fontSize: '0.675rem' }}>
                {permissionsLoading ?
                  <></> :
                  permissions?.data?.filter(({code})=>code!=="add").map(({ code }) => code).join(' / ')}
              </TableCell>
            )}
          </TableRow>
          {Object.keys(newDwssUserOrGroupPermissions).map(contractName =>
            <TableRow>
              <TableCell style={{ padding: '0' }}>{contractName}</TableCell>
              {permissionCategories.map(type => {
                const data = newDwssUserOrGroupPermissions[contractName]
                  .find(item => {
                    return item.contractNo === contractName && item.type === type.code
                  })
                // console.log('deep data', contractName, type.code, data?.id, data)

                return (
                  <TableCell align='center' size="medium" style={{ padding: '0' }}>
                    {/*data?.id*/}
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup row >
                        {permissionsLoading ?
                          <></> :
                          permissions?.data?.filter(({code})=>code!=="add").map(permission =>
                            <FormControlLabel
                              control={<Checkbox
                                style={{ padding: '0 .2rem' }}
                                checked={isChecked({ permissionName: permission.code, dwssId: data?.id, contractName, type: type.code })}
                                onChange={handlePermissionCheckbox({ dwssId: data?.id, contractName, type: type.code })}
                                name={permission.code} />}
                                // label={<Typography style={{ fontSize: '.8rem' }}>{permission.code}</Typography>}
                                // labelPlacement="top"
                              style={{ margin: '0' }}
                            />
                          )
                        }
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                )
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )

}

export default PermissionMatrixTable;
