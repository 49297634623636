import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { tableStyles } from "styles";

function LogViewTable({ logsData }) {
  const useStyles = makeStyles((theme) => tableStyles(theme));
  const classes = useStyles();
  // console.log('logsData',logsData)

  return (logsData) && (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="left">
              #
            </TableCell>
            <TableCell className={classes.header} align="right">
              Datetime
            </TableCell>
            <TableCell className={classes.header} align="right">
              Status
            </TableCell>
            <TableCell className={classes.header} align="right">
              Reason
            </TableCell>
            <TableCell className={classes.header} align="right">
              User
            </TableCell>
            <TableCell className={classes.header} align="right">
              From
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logsData &&
            logsData.map((log, i) => {
              return (
                <TableRow key={log.id}>
                  <TableCell align="left">{log.id}</TableCell>
                  <TableCell align="right">{moment(log.createdAt).format('DD-MMM-YYYY HH:mm:ss')}</TableCell>
                  <TableCell align="right">{log.status}</TableCell>
                  <TableCell align="right">{log.failedMessage}</TableCell>
                  <TableCell align="right">
                    {log.user}
                  </TableCell>
                  <TableCell align="right">
                    {log.origin}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LogViewTable;
