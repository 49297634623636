import React, { useState } from 'react';

// Popup dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import PopupNotification from 'components/General/PopupNotification';

import * as Mui from '@material-ui/core';
import styled from "styled-components";

import PermissionMatrixTable from 'components/Permission/PermissionMatrixTable';

import { useDwssPermisionByUserId, updateDwssUser } from "services/DwssUser";
import { useDwssPermisionByGroupId, updateDwssGroup } from "services/DwssGroup";


const Button = styled(Mui.Button)`
  width: 5em;
  padding: 1px 16px;
  text-transform: none;
`;


export default function PermissionBtn({
  displayName,
  userId,
  groupId,
  savePermissions,
}) {

  const {
    dwssUserPermissionsData,
    dwssUserPermissionsLoading,
    dwssUserPermissionsError
  } = useDwssPermisionByUserId(userId);

  const {
    dwssGroupPermissionsData,
    dwssGroupPermissionsLoading,
    dwssGroupPermissionsError
  } = useDwssPermisionByGroupId(groupId);

  const [dwssUserOrGroupPermissions, setDwssUserOrGroupPermissions] = useState(null)
  const [newDwssUserOrGroupPermissions, setNewDwssUserOrGroupPermissions] = useState(null)
  const [open, setOpen] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState('');
  // console.log({dwssUserOrGroupPermissions})

  const handleClickOpen = () => {
    setDwssUserOrGroupPermissions(dwssUserPermissionsData?.data || dwssGroupPermissionsData?.data);
    setNewDwssUserOrGroupPermissions(dwssUserPermissionsData?.data?.groupByContract || dwssGroupPermissionsData?.data?.groupByContract)

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isChecked = (data) => {
    const { permissionName, dwssId, contractName, type } = data;

    const thisPermissionId = dwssUserOrGroupPermissions.permissionTypes.find(item => item.code === permissionName)?.id

    return !!newDwssUserOrGroupPermissions[contractName].find(dwssUser => {
      return dwssUser.type === type
        && !!dwssUser.permissions.find(permission => permission.permissionId === thisPermissionId)
    })

  }

  const handlePermissionCheckbox = (data) => (e) => {
    const { name, value, checked } = e.target
    const { dwssId, contractName, type } = data;
    // console.log('ticking...', name, value, checked, data)

    newDwssUserOrGroupPermissions[contractName] = newDwssUserOrGroupPermissions[contractName].map(dwssUser => {
      const checkedPermissionId = dwssUserOrGroupPermissions.permissionTypes.find(item => item.code === name)?.id;

      const isInExistingPermissions = dwssUser.permissions.find(permission => permission.permissionId === checkedPermissionId);

      if (type === dwssUser.type && checked) {
        if (isInExistingPermissions) {
          const index = dwssUser.permissions.findIndex(permission => permission.permissionId === checkedPermissionId);
          dwssUser.permissions.splice(index, 1, { permissionId: checkedPermissionId });
        } else
          dwssUser.permissions.push({ permissionId: checkedPermissionId });
      }

      /* handle uncheck */
      if (type === dwssUser.type && !checked && isInExistingPermissions) {
        const index = dwssUser.permissions.findIndex(permission => permission.permissionId === checkedPermissionId);

        dwssUser.permissions.splice(index, 1);
      }

      // console.log('1', type, dwssUser.type, type !== dwssUser.type)
      // console.log('2', dwssUser.permissions.length)
      // console.log('3', { permissionId: checkedPermissionId })
      // console.log('4', dwssUser.permissions)

      return {
        ...dwssUser,
        permissions: dwssUser.permissions,
      }
    })

    // console.log('see see the checked permissions', newDwssUserPermissions[contractName])
    setNewDwssUserOrGroupPermissions({ ...newDwssUserOrGroupPermissions, })
  }

  /* render ui */
  const popupMessasge = (data) => {
    const { message, open } = data
    return (<PopupNotification open={open} setOpen={setPopup} message={message} />)
  }


  return (!dwssUserPermissionsLoading || !dwssGroupPermissionsLoading?.data) && (
    <div>
      <Button aria-describedby={userId} variant="contained" color="primary" onClick={handleClickOpen}>
        Manage
      </Button>
      <Dialog maxWidth={'md'} fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Permission{`${displayName && ' - ' + displayName || ''}`}
        </DialogTitle>
        <DialogContent>
          <PermissionMatrixTable
            dwssUserOrGroupPermissions={dwssUserOrGroupPermissions}
            newDwssUserOrGroupPermissions={newDwssUserOrGroupPermissions}
            isChecked={isChecked}
            handlePermissionCheckbox={handlePermissionCheckbox}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleClose()
            setPopup(false)
          }} >
            Close
          </Button>
          <Button onClick={() => {
            savePermissions({
              userId,
              groupId,
              dwssUserOrGroupPermissions,
              newDwssUserOrGroupPermissions,

            })
            setPopup(true)
            setPopupMessage('Saved')
          }} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {popupMessasge({
        message: popupMessage,
        open: popup,
      })}

    </div>
  );

}
