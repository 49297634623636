import ApiCall from "utils/ApiCall";
import useSWR from "swr";

// const route = `${process.env.REACT_APP_API}/api/permissions`;

const getPermissions = async () => {
  return await ApiCall.get(`${process.env.REACT_APP_API}/api/permissions`);
};

const createPermission = async (data) => {
  return await ApiCall.post(
    `${process.env.REACT_APP_API}/api/permission/register`,
    data,
  );
};

const updatePermission = async (data) => {
  return await ApiCall.update(
    `${process.env.REACT_APP_API}/api/permission/${data.id}`,
    data,
  );
};

const deletePermission = async (id) => {
  return await ApiCall.delete(
    `${process.env.REACT_APP_API}/api/permission/${id}`,
  );
};

const usePermissions = (groupId) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/permissions?type=file`,
    ApiCall.swrFetcher(),
  );
  //console.log("useGroups", data);

  return {
    permissionsData: data,
    permissionsLoading: !error && !data,
    permissionsError: error,
  };
};

export { getPermissions, createPermission, updatePermission, deletePermission, usePermissions };
