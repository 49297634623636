import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { tableStyles } from "styles";

function LogViewTable({ logsData }) {
  const useStyles = makeStyles((theme) => tableStyles(theme));
  const classes = useStyles();
  // console.log('logsData',logsData)

  return (logsData) && (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="left"> # </TableCell>
            <TableCell className={classes.header} align="right"> Timestamp </TableCell>
            <TableCell className={classes.header} align="right"> User </TableCell>
            <TableCell className={classes.header} align="right"> Action </TableCell>
            <TableCell className={classes.header} align="left"> Resource </TableCell>
            <TableCell className={classes.header} align="right"> IP address </TableCell>
            {/*
            <TableCell className={classes.header} align="right">
              Response code
            </TableCell>
            */}
            <TableCell className={classes.header} align="right"> Status </TableCell>
            <TableCell className={classes.header} align="right"> Contract </TableCell>
            <TableCell className={classes.header} align="right"> Type </TableCell>
            <TableCell className={classes.header} align="right"> Path </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logsData &&
            logsData.map((log) => {
              return (
                <TableRow key={log.id}>
                  <TableCell align="left">{log.id}</TableCell>
                  <TableCell align="right">{moment(log.updatedAt).format('DD-MMM-YYYY HH:mm:ss')}</TableCell>
                  <TableCell align="right">{log.username}</TableCell>
                  <TableCell align="right">{log.action}</TableCell>
                  <TableCell align="left">{log.route}</TableCell>
                  <TableCell align="right">{log.ipAddress}</TableCell>
                  {/*<TableCell align="right">{log.responseCode}</TableCell>*/}
                  <TableCell align="right">{log.status}</TableCell>
                  <TableCell align="left">{log.contractNo}</TableCell>
                  <TableCell align="left">{log.type}</TableCell>
                  <TableCell align="left">{log.path}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LogViewTable;
