import React, { useState, useEffect } from "react";
import styled from "styled-components";

const EthereumMonitorPage = styled.div`
  margin: 4rem 2rem;
  font-size: 2em;
`;

const EthereumIframe = styled.iframe`
  width: 90vw;
  height: 50vh;
`;

export default function EthereumMonitor() {
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       console.log("eeeeemomomomo");
  //     } catch (err) {
  //       console.log("errrr", err);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    // <div>??????????</div>
    <EthereumMonitorPage>
      <div>Overview of the ethereum's nodes info</div>
      <EthereumIframe
        src="https://dsd-eth-mon-dev.7ha7ha.life/"
        title="DSD Ethereum Monitor"
      ></EthereumIframe>
    </EthereumMonitorPage>
  );
}
