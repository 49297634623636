import ApiCall from "utils/ApiCall";
import useSWR from "swr";

// const route = `${process.env.REACT_APP_API}/api/users`;

const useGetContractsByMyGroup = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/contracts/by-group`,
    ApiCall.swrFetcher(),
  );

  return {
    contractsInfo: data,
    contractsLoading: !error && !data,
    contractsError: error,
  };
};

const getAllContract = async () => {
  return await ApiCall.get(`${process.env.REACT_APP_API}/api/contracts`);
};

const getContractById = async (id) => {
  return await ApiCall.get(`${process.env.REACT_APP_API}/api/contract/${id}`);
};

const getContractByIdRics = async (id) => {
  return await ApiCall.get(
    `${process.env.REACT_APP_API}/api/contract/${id}/risc`,
  );
};

const getContractByIdSiteDiary = async (id) => {
  return await ApiCall.get(
    `${process.env.REACT_APP_API}/api/contract/${id}/site-diary`,
  );
};

const getContractByIdPhoto = async (id) => {
  return await ApiCall.get(
    `${process.env.REACT_APP_API}/api/contract/${id}/photo`,
  );
};

const saveContract = async (data) => {
  return await ApiCall.post(`${process.env.REACT_APP_API}/api/contract`, data);
};

// const updateContract = async (data) => {
//   return await ApiCall.update(
//     `${process.env.REACT_APP_API}/api/user/${data.id}`,
//     data,
//   );
// };

// const deleteContract = async (id) => {
//   return await ApiCall.delete(`${process.env.REACT_APP_API}/api/user/${id}`);
// };

export {
  // getContractsByMyGroup,
  useGetContractsByMyGroup,
  getAllContract,
  saveContract,
  getContractById,
  getContractByIdRics,
  getContractByIdSiteDiary,
  getContractByIdPhoto,
};
