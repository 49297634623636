import React, { useState, useEffect } from "react";
import useSWR, { mutate } from "swr";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { LogViewTable } from "components/LoginLog";
import { useLoginLogs } from "services/Logs";

//import { getStatuses } from "services/Status";
//import { validateGroup } from "utils/validationRules";
import { searchBoxStyles } from "styles";


export default function LogView() {
  const useStyles = makeStyles((theme) => searchBoxStyles(theme));
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [permission, setPermission] = useState([]);
  const { loginLogsData, loginLogsLoading, loginLogsError } = useLoginLogs();

  const loginLogs = loginLogsData?.data;
  const [logs, setLogs] = useState([]);


  //const insertOrUpdateGroup = async (group) => {
  //  if (!group) return;

  //  //group.statusId = statuses.find(({code})=>code==="active")?.id;
  //  const { value: data, error } = validateGroup.validate(group, {
  //    allowUnknown: true,
  //    abortEarly: false,
  //    stripUnknown: true,
  //  });
  //  console.log("value", data);
  //  console.log("error", !error, error?.details);
  //  if (error) {
  //    setErrorMessages(error?.details);
  //    return;
  //  }

  //  setErrorMessages([]);
  //  const result = await createGroup(data);

  //  mutate(`${process.env.REACT_APP_API}/api/grp`);
  //  //setMember(defaultLog);
  //  setOpen(false);
  //};

  //const handleClickOpen = () => {
  //  setLogs(defaultLog);
  //  setOpen(true);
  //};

  //const handleClose = () => {
  //  setMember(defaultMember);
  //  setOpen(false);
  //};

  //const handleChange = (event) => {
  //  const { name, value, type, checked, art } = event.target;
  //  console.log("ds", name, value, art, type, checked);

  //  setLogs({ ...group, [name]: value });
  //};

  const searchLogs = () => {
    const tuneString = (s) => (!s ? "" : s.toString().toLowerCase());

    const filteredLogs = loginLogs?.filter((log) => {
      // console.log("?", Object.keys(group));
      return !!Object.keys(log).find((key) =>
        tuneString(log[key]).includes(tuneString(searchText)),
      );
    });

    return filteredLogs;
  };

  return (
    <div style={{ margin: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5rem",
          alignItems: "center",
        }}
      >
        <div>
          <h4>Logging</h4>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Paper component="form" className={classes.root}>
            <SearchIcon />
            <InputBase
              name={searchText}
              className={classes.input}
              placeholder="Search Log"
              inputProps={{ "aria-label": "search group" }}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </Paper>
        </div>
      </div>

      {//loginLogsLoading || loading ? (
        loginLogsLoading ? (
          <>loading...</>
        ) : (
          <LogViewTable
            logsData={searchLogs()}
            setLogs={setLogs}
            setOpen={setOpen}
          />
        )}

    </div>
  );
}
