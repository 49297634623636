import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";

import Layout from "./Layout";
import { defaultMaterialTheme } from "./AppStyle";
import "./App.css";

function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Router>
          <div className="App">
            <Helmet>
              <title>DSD Role Management System</title>
              <meta name="description" content="App Description" />
              <meta name="theme-color" content="#008f68" />
            </Helmet>
            {/* All routes start in the Layout components */}
            <Layout />
          </div>
        </Router>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
