import ApiCall from "utils/ApiCall";
import useSWR from "swr";

const useUserGroupByGroupId = (id) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/user-group/${id}`,
    ApiCall.swrFetcher(),
  );
  //console.log("useGroups", data);

  return {
    userGroupData: data,
    userGroupLoading: !error && !data,
    userGroupError: error,
  };
};

const getUserGroups = async () => {
  return await ApiCall.get(`${process.env.REACT_APP_API}/api/user-groups`);
};

const updateUserGroup = async (data) => {
  return await ApiCall.post(`${process.env.REACT_APP_API}/api/user-group`, data);
};


export {useUserGroupByGroupId, getUserGroups, updateUserGroup};
