import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import * as Mui from '@material-ui/core';
import styled from "styled-components";

const Button = styled(Mui.Button)`
  width: 5em;
  padding: 1px 16px;
  text-transform: none;
`;

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function ShowContractsBtn({ contracts }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  const handleClose = () => {
    setAnchorEl(null);

  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        View
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',

        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',

        }}
      >
        {contracts?.map(({ contractNo },i) => {
          return <Typography key={i} className={classes.typography}>{contractNo}</Typography>
        })}
      </Popover>
    </div>

  );

}
