import ApiCall from "utils/ApiCall";
import useSWR from "swr";

const useDwssPermisionByUserId = (userId) => {
  try {
    // if (!userId)
    //   throw new Error('err: useDwssPermisionByUserId() missing userId....');

    const { data, error } = useSWR(
      `${process.env.REACT_APP_API}/api/dwss-permissions-by-user?userId=${userId}`,
      ApiCall.swrFetcher(),
    );
    //console.log("useGroups", data);

    return {
      dwssUserPermissionsData: data,
      dwssUserPermissionsLoading: !error && !data,
      dwssUserPermissionsError: error,
    };
  } catch (err) {
    throw err
  }
};

const updateDwssUser = async (data) => {
  try {
    const { userId, newDwssUserOrGroupPermissions:newDwssUserPermissions } = data;
    return await ApiCall.post(`${process.env.REACT_APP_API}/api/dwss-user/${userId}`, newDwssUserPermissions);
  } catch (err) {
    throw err;
  }
};


export { useDwssPermisionByUserId, updateDwssUser };
