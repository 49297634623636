import React, { useState } from 'react';
import PropTypes from "prop-types";
//import * as Mui from '@material-ui/core';
//import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Snackbar for message hints after action
import Snackbar from '@material-ui/core/Snackbar';

export default function PopupNotification(props) {
  //console.log('popup props', props)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false)
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={props.open}
      autoHideDuration={5000}
      onClose={handleClose}
      message={props.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}

PopupNotification.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
