/* eslint-disable no-use-before-define */

import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { getContractGroupByContractId } from "services/ContractGroup";
import styled from "styled-components";

const Dropdown = styled(Autocomplete)`
  margin: 1rem 0;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function GroupsComboBox({
  groups,
  selectedGroupIds,
  setSelectedGroupIds,
  contract,
}) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getGroupInfo = async () => {
      const result = await getContractGroupByContractId(contract.id);

      const existingContractGroups = result.map(({ groupId }) => {
        return groups.find(({ id }) => id === groupId);
      });

      setSelectedGroupIds(existingContractGroups);
      setLoading(false);
    };

    getGroupInfo();
  }, []);

  return loading ? (
    <>loading...</>
  ) : (
    <Dropdown
      multiple
      id="checkboxes-tags-demo"
      options={groups}
      value={selectedGroupIds}
      onChange={(event, data) => {
        // console.log("data", data);
        setSelectedGroupIds(data);
      }}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Select Groups" />
      )}
    />
  );
}
