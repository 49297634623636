import { createTheme } from '@material-ui/core';

export const defaultMaterialTheme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
    h1: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "36px"
    },
  }
});
