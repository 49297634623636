const Joi = require("joi");

export const validateUser = Joi.object({
  id: Joi.number().integer(),
  email: Joi.string().email({ tlds: false }).required().messages({
    "string.email": `please enter valid email`,
    //"string.email": `please enter valid email`,
  }),
  username: Joi.string().allow("").optional(),
  password: Joi.string().pattern(new RegExp('^[a-zA-Z0-9#?!@$ %^&*-]{3,30}$')).messages({
    "string.empty": `password is required`,
    "string.pattern.base": `password length should between 3-30 characters`,
    "number.base": `password is required`,
    "any.required": `password is required`,
  }),
  firstName: Joi.string().max(30).required().messages({
    "string.empty": `first name is required`,
  }),
  lastName: Joi.string().max(30).required().messages({
    "string.empty": `last name is required`,
  }),
  organization: Joi.string().allow("").optional(),
  groupId: Joi.number().integer().required().messages({
    "string.empty": `organisation is required`,
    "number.base": `organisation is required`,
    "any.required": `organisation is required`,
  }),
  permissionId: Joi.number().integer().required().messages({
    "string.empty": `permission is required`,
    "number.base": `permission is required`,
    "any.required": `permission is required`,
  }),
  statusId: Joi.number().integer().required().messages({
    "string.empty": `status is required`,
    "number.base": `status is required`,
    "any.required": `status is required`,
  }),
  role: Joi.string().required().messages({
    "string.empty": `role is required`,
  }),
  type: Joi.string().required().messages({
    "string.empty": `type is required`,
  }),
  //remark: Joi.any().optional(),
  remark: Joi.string().alphanum().allow(null, "").empty("").optional(),
})
  .or("id", "password")
  .messages({
    "object.missing": `password is required`,
    "object.xor": `password is required`,
  });

export const validateContract = Joi.object({
  id: Joi.number().integer(),
  contractNo: Joi.string()
    .required()
    .messages({
      "any.required": `Contract No is required`,
      "string.empty": `Contract No is required`,
    }),
  projectClient: Joi.string()
    .required()
    .messages({
      "any.required": `Project Client is required`,
      "string.empty": `Project Client is required`,
    }),
  projectConsultant: Joi.string()
    .required()
    .messages({
      "any.required": `Project Consultant is required`,
      "string.empty": `Project Consultant is required`,
    }),
  projectContractor: Joi.string()
    .required()
    .messages({
      "any.required": `Project Contractor is required`,
      "string.empty": `Project Contractor is required`,
    }),
  projectId: Joi.string()
    .required()
    .messages({
      "any.required": `Project ID is required`,
      "string.empty": `Project ID is required`,
    }),
  projectLocation: Joi.string()
    .required()
    .messages({
      "any.required": `Project Location is required`,
      "string.empty": `Project Location is required`,
    }),
  projectName: Joi.string()
    .required()
    .messages({
      "any.required": `Project Name is required`,
      "string.empty": `Project Name is required`,
    }),
  projectTitle: Joi.string()
    .required()
    .messages({
      "any.required": `Project Title is required`,
      "string.empty": `Project Title is required`,
    }),
});

export const validateGroup = Joi.object({
  id: Joi.number().integer(),
  name: Joi.string()
    .required()
    .messages({
      "any.required": `Group name is required`,
      "string.empty": `Group name is required`,
    }),
  remark: Joi.string()
    .allow(null, "")
    .empty("")
    .optional(),
  statusId: Joi.number().integer()
    .required()
    .messages({
      "any.required": `Please select a status`,
      "number.base": `Please select a status`,
    }),
});
