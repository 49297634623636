import { createSlice } from "@reduxjs/toolkit";

const ModalSlice = createSlice({
  name: "modal",
  initialState: {
    type: "",
  },
  reducers: {
    setModalOpen: (state, action) => {
      const { modalType } = action.payload;
      return { ...state, type: modalType };
    },
    setModalClose: (state) => {
      return { ...state, type: "" };
    }
  }
});

export const { setModalOpen, setModalClose } = ModalSlice.actions;

export default ModalSlice.reducer;
