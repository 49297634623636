import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ApiCall from "../utils/ApiCall";
import MuiAlert from "@material-ui/lab/Alert";
import DsdLogo from "assets/images/logo.png";
import { useHistory } from "react-router-dom";
import { loginStyles } from "styles";

export default function SignIn() {
  const history = useHistory();
  const useStyles = makeStyles((theme) => loginStyles(theme));
  const classes = useStyles();

  const [member, setMember] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    // console.log("ds", name, value, type, checked);

    setMember({ ...member, [name]: value });
  };

  const login = async (event) => {
    try {
      event.preventDefault();
      const accessToken = await ApiCall.post(
        `${process.env.REACT_APP_API}/api/user/login`,
        {
          username: member.email,
          password: member.password,
          loginFrom: "role-management",
        },
      );

      if (!accessToken) {
        return;
      }

      const loginedUserInfo = JSON.parse(atob(accessToken.split(".")[1]));
      // console.log("loginedUserInfo", loginedUserInfo);

      localStorage.accessToken = accessToken;
      localStorage.userId = loginedUserInfo.id;
      history.push("/user");
    } catch (err) {
      setMessage(err?.message);
      return;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={DsdLogo} width="90px" />

        <Typography component="h1" variant="h4">
          DSD Blockchain
        </Typography>
        <Typography component="h1" variant="h5">
          User Management
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            onChange={handleChange}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onKeyUp={(evt) => {
              if (evt.key === "Enter") {
                login(evt);
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            onChange={handleChange}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onKeyUp={(evt) => {
              if (evt.key === "Enter") {
                login(evt);
              }
            }}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => login(e)}
          >
            Sign In
          </Button>
          {message && <MuiAlert severity="error">{message}</MuiAlert>}
        </form>
      </div>
    </Container>
  );
}
