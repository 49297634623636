import ApiCall from "utils/ApiCall";
import useSWR from "swr";

const useLoginLogs = () => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/user-logins`,
    ApiCall.swrFetcher(),
  );
  //console.log("login logs", data);

  return {
    loginLogsData: data,
    loginLogsLoading: !error && !data,
    loginLogsError: error,
  };
};

const useActivityLogs = (pageInfo) => {
  const {pagination, setPagination} = pageInfo
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API}/api/activity-logs?page=${pagination.page}&items=${pagination.items}`,
    ApiCall.swrFetcher(),
  );
  // console.log("activity logs", data);

  return {
    activityLogsData: data,
    activityLogsLoading: !error && !data,
    activityLogsError: error,
  };
};

export { useLoginLogs, useActivityLogs };
