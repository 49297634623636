import { Switch, Route, useHistory } from "react-router-dom";
import Contract from "views/Contract";
import EthereumMonitor from "views/EthereumMonitor";
import Dashboard from "views/Dashboard";
import User from "views/User";
import Group from "views/Group";
import Permission from "views/Permission";
import LoginLog from "views/LoginLog";
import ActivityLog from "views/ActivityLog";
import Login from "views/Login";
import LoggedInLayout from "components/LoggedInLayout";

export default function Layout() {
  const { accessToken } = localStorage;
  const history = useHistory();

  // console.log("???", accessToken);

  !accessToken && history.push("/login");

  return (
    <>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/user">
          <LoggedInLayout>
            <User />
          </LoggedInLayout>
        </Route>
        <Route path="/login-logs">
          <LoggedInLayout>
            <LoginLog />
          </LoggedInLayout>
        </Route>
        <Route path="/activity-logs">
          <LoggedInLayout>
            <ActivityLog />
          </LoggedInLayout>
        </Route>
        <Route path="/group">
          <LoggedInLayout>
            <Group />
          </LoggedInLayout>
        </Route>
        <Route path="/permission">
          <LoggedInLayout>
            <Permission />
          </LoggedInLayout>
        </Route>
        <Route path="/contract">
          <LoggedInLayout>
            <Contract />
          </LoggedInLayout>
        </Route>
        <Route path="/ethereum-monitor">
          <LoggedInLayout>
            <EthereumMonitor />
          </LoggedInLayout>
        </Route>
        <Route path="/dashboard">
          <LoggedInLayout>
            <Dashboard />
          </LoggedInLayout>
        </Route>
        <Route path="/">
          <LoggedInLayout>
            <Dashboard />
          </LoggedInLayout>
        </Route>
      </Switch>
    </>
  );
}
