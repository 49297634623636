import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Mui from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import GroupsComboBox from "./GroupsComboBox";
import { getContractGroupByContractId } from "services/ContractGroup";
import { validateContract } from "utils/validationRules";

const ErrorMessageBox = styled.div`
  color: red;
  margin: 0 1.2rem;
`;

const Button = styled(Mui.Button)`
  width: 5em;
  text-transform: none;
`;

function Popup(props) {
  const {
    index,
    onClose,
    open,
    newContract,
    contract,
    contracts,
    setContract,
    // setContracts,
    groups,
    handleSaveContractGroup,
    handleSaveContract,
    selectedGroupIds,
    setSelectedGroupIds,
    //contractGroups,
  } = props;
  const [errorMessages, setErrorMessages] = useState([]);

  const handleChange = (changingObj) => (e, data) => {
    //console.log(e, data);
    const { name, value, checked, type } = e.target;
    // console.log("name, value", name, value);
    const index = contracts.findIndex((item) => {
      return item.id === contract.id;
    });
    // console.log("changingObj", contract);

    setContract({ ...changingObj, [name]: value });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      onClose={handleClose}
      open={open || newContract}
    >
      <DialogTitle id="simple-dialog-title">
        Group(s) assignment for contract
      </DialogTitle>

      {errorMessages.map(({ message }) => (
        <ErrorMessageBox>{message}</ErrorMessageBox>
      ))}

      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="projectId"
          id="projectId"
          label="Project ID"
          type="text"
          value={contract.projectId}
          onChange={handleChange(contract)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          name="contractNo"
          id="contractNo"
          label="Contract No."
          type="text"
          value={contract.contractNo}
          onChange={handleChange(contract)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          name="projectName"
          id="projectName"
          label="Project name"
          type="text"
          value={contract.projectName}
          onChange={handleChange(contract)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          name="projectTitle"
          id="projectTitle"
          label="Project title"
          type="text"
          value={contract.projectTitle}
          onChange={handleChange(contract)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          name="projectLocation"
          id="projectLocation"
          label="Project location"
          type="text"
          value={contract.projectLocation}
          onChange={handleChange(contract)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          name="projectClient"
          id="projectClient"
          label="Project client"
          type="text"
          value={contract.projectClient}
          onChange={handleChange(contract)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          name="projectConsultant"
          id="projectConsultant"
          label="Project consultant"
          type="text"
          value={contract.projectConsultant}
          onChange={handleChange(contract)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          name="projectContractor"
          id="projectContractor"
          label="Project contractor"
          type="text"
          value={contract.projectContractor}
          onChange={handleChange(contract)}
          fullWidth
        />
        {!newContract && (
          <GroupsComboBox
            groups={groups}
            selectedGroupIds={selectedGroupIds}
            setSelectedGroupIds={setSelectedGroupIds}
            contract={contract}
            //contractGroups={contractGroups}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="small"
          onClick={() => {
            const { value: data, error } = validateContract.validate(contract, {
              allowUnknown: true,
              abortEarly: false,
              stripUnknown: true,
            });
            // console.log("error?.details", error?.details);

            if (error) {
              setErrorMessages(error?.details);
              return;
            }
            setErrorMessages([]);

            handleSaveContractGroup(selectedGroupIds);
            handleSaveContract(data);
            onClose();
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Popup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
};

export default function ContractGroupDialog({
  index,
  groups,
  handleSaveContractGroup,
  handleSaveContract,
  selectedGroupIds,
  setSelectedGroupIds,
  contract,
  contracts,
  newContract,
  setNewContract,
  setContract,
  setContracts,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = async () => {
    setNewContract(false);
    setContract({ ...contracts[index] });

    setOpen(true);
  };

  const handleClose = () => {
    setNewContract(false);
    setOpen(false);
  };

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        Edit
      </Button>
      <Popup
        index={index}
        open={open}
        onClose={handleClose}
        groups={groups}
        handleSaveContractGroup={handleSaveContractGroup}
        handleSaveContract={handleSaveContract}
        selectedGroupIds={selectedGroupIds}
        setSelectedGroupIds={setSelectedGroupIds}
        newContract={newContract}
        contract={contract}
        contracts={contracts}
        setContract={setContract}
        setContracts={setContracts}
        //contractGroups={contractGroups}
      />
    </div>
  );
}
